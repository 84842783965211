
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { Info } from '@/types/scenery'

@Component({
  components: { MapLocation },
  filters: {
    isNetRed (value: string) {
      return value ? value === '1'
        ? '是' : value === '0'
          ? '否' : '' : ''
    }
  }
})

export default class SceneryDetail extends Vue {
  private info: Info = {
    projectId: '',
    sceneryName: '',
    buildDate: '',
    description: '',
    resourceList: [],
    resourcesLocationList: [],
    sceneryCode: ''
  }

  created () {
    this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.scenery.selectSceneryBySceneryId, {
      sceneryId: this.$route.params.sceneryId
    }).then(res => {
      this.info = res || {}
    })
  }
}
